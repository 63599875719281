/**
 * Breadcrumbs components.
 * @module components/theme/Breadcrumbs/Breadcrumbs
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, Container, Segment } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';

import { Icon } from '@plone/volto/components';
import { getBreadcrumbs } from '@plone/volto/actions';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';

import homeSVG from '@plone/volto/icons/home.svg';

const messages = defineMessages({
  youarehere: {
    id: 'You are here:  ',
    defaultMessage: 'You are here:  ',
  },
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
});

/**
 * Breadcrumbs container class.
 */
export class BreadcrumbsComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getBreadcrumbs: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    root: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
  };

  componentDidMount() {
    if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
      this.props.getBreadcrumbs(getBaseUrl(this.props.pathname));
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
        this.props.getBreadcrumbs(getBaseUrl(nextProps.pathname));
      }
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    /* Do not show breadcrumbs on home and first level pages */
    let depth = this.props.pathname.split("/").length;
    let include_breadcrumbs = true;
    if (depth < 3) {
      include_breadcrumbs = false;
    }
    return (
      <div test-depth={depth}>
        {include_breadcrumbs ? (
          <Segment
            role="navigation"
            aria-label={this.props.intl.formatMessage(messages.breadcrumbs)}
            className="breadcrumbs"
            secondary
            vertical
          >
            <Container>
              <Breadcrumb>
                  <span className="home">{this.props.intl.formatMessage(messages.youarehere)}</span>
                  <Link key={this.props.root} to={this.props.root} className="section">
                    {this.props.intl.formatMessage(messages.home)}
                  </Link>
                  {this.props.items.map((item, index, items) => [
                    <Breadcrumb.Divider key={`divider-${item.url}`} />,
                    index < items.length - 1 ? (
                      <Link key={item.url} to={item.url} className="section">
                        {item.title}
                      </Link>
                    ) : (
                      <Breadcrumb.Section key={item.url} active>
                        {item.title}
                      </Breadcrumb.Section>
                    ),
                  ])}
              </Breadcrumb>
            </Container>
          </Segment>
        ):(' ')}
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.breadcrumbs.items,
      root: state.breadcrumbs.root,
    }),
    { getBreadcrumbs },
  ),
)(BreadcrumbsComponent);
