/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

import installTeaserBlock from './components/Blocks/Teaser';

const applyConfig = (config) => {
  config.settings['volto-navigation-dropdown'] = {
    // Not working so added below
    // navDepth: 2,
    // dropdownmenu: {
    //   selfInContents: false,
    // },
  };
  config.settings['volto-dropdownmenu'] = {
    options: {
      clickableNavigationRoots: true, //if true, a checkbox option in dropdown menu appears
    },
  };

  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['en'],
    defaultLanguage: 'en',
    // config.settings["volto-navigation-dropdown"]
    navDepth: 2,
    dropdownmenu: {
      selfInContents: false,
    },
  };

  const final = [installTeaserBlock].reduce((acc, apply) => apply(acc), config);

  return final;
};
export default applyConfig;
