import React from 'react';
import PropTypes from 'prop-types';
import { Message, Icon } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { isInternalURL } from '@plone/volto/helpers';
import { MaybeWrap } from '@plone/volto/components';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import config from '@plone/volto/registry';

import './style.less';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const TeaserIconTemplate = (props) => {
  const { className, data, isEditMode } = props;
  const { icon_name } = data;
  const intl = useIntl();
  const href = data.href?.[0];

  const { openExternalLinkInNewTab } = config.settings;

  return (
    <div className={cx('block teaser icon-teaser', className)}>
      <>
        {!href && isEditMode && (
          <Message>
            <div className="grid-teaser-item placeholder">
              <img src={imageBlockSVG} alt="" />
              <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
            </div>
          </Message>
        )}
        {href && (
          <MaybeWrap
            condition={!isEditMode}
            as={UniversalLink}
            href={href['@id']}
            target={
              data.openLinkInNewTab ||
              (openExternalLinkInNewTab && !isInternalURL(href['@id']))
                ? '_blank'
                : null
            }
          >
            <div className="grid-teaser-item default">
              {icon_name && <Icon circular name={icon_name} size="large" />}
              <div className="content">
                {data?.head_title && (
                  <div className="headline">{data.head_title}</div>
                )}

                <h2>{data?.title}</h2>
                <div className="small-break"></div>
                {!data.hide_description && <p>{data?.description}</p>}
              </div>
            </div>
          </MaybeWrap>
        )}
      </>
    </div>
  );
};

TeaserIconTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserIconTemplate;
