import TeaserHomePageTemplate from './TeaserHomePageTemplate';
import TeaserIconTemplate from './TeaserIconTemplate';
import TeaserSectionHeadTemplate from './TeaserSectionHeadTemplate';

export default (config) => {
  config.blocks.blocksConfig.teaser = {
    ...config.blocks.blocksConfig.teaser,
    variations: [
      ...config.blocks.blocksConfig.teaser.variations,
      {
        id: 'homepage_variation',
        title: 'Homepage',
        template: TeaserHomePageTemplate,
        isDefault: false,
      },
      {
        id: 'sectionhead_variation',
        title: 'SectionHead',
        template: TeaserSectionHeadTemplate,
        isDefault: false,
      },
      {
        id: 'icon_variation',
        title: 'Icon',
        template: TeaserIconTemplate,
        isDefault: false,
        schemaEnhancer: ({ schema, FormData, intl }) => {
          schema.fieldsets[0].fields.push('icon_name');
          schema.properties.icon_name = {
            title: 'Icon name',
            description: (
              <>
                Ex. 'volume off'. See{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://react.semantic-ui.com/elements/icon/"
                >
                  Semantic UI icons
                </a>
              </>
            ),
          };
          schema.fieldsets[0].fields.splice(
            schema.fieldsets[0].fields.indexOf('preview_image'),
            1,
          );
          schema.fieldsets[0].fields.splice(
            schema.fieldsets[0].fields.indexOf('head_title'),
            1,
          );
          return schema;
        },
      },
    ],
  };

  return config;
};
