/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React, { createElement, Component, useEffect } from 'react';
import { Container, Grid, Image, List, Segment } from 'semantic-ui-react';
import { map } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import Helmet from '@plone/volto/helpers/Helmet/Helmet';
import footerImagePath from './footer_logo.svg';
import footerSmallImagePath from './footer_logo_small.svg';
import youtubeImagePath from './youtube-white.svg';
import linkedInImagePath from './linkedin-white.svg';
import facebookImagePath from './facebook-white.svg';
import twitterImagePath from './twitter-white.svg';
import slideshareImagePath from './slideshare-white.svg';
import googleplusImagePath from './googleplus-white.svg';

function ConstantContactsFooter() {
  useEffect(() => {
    const ctct_m = "f7305311bcea4b9e33d719512a25114c";

    const script = document.createElement('script');
    script.id = "signupScript";
    script.src = "//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js";
    script.async = true;
    //TODO
    // script.async = "defer";

    window._ctct_m = ctct_m;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
     <Segment basic className="constant-contacts-wrapper">
         <div className="constrantcontacts">
           <div className="row collapse">
              <div className="ctct-inline-form"
                   data-form-id="fd2a3a29-9cd5-4213-8099-f7ae935fa43f"></div>
           </div>
         </div>
     </Segment>
  );
}

class JivoChat extends Component {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Segment basic className="jivochat-wrapper">
        <Container>
          <div className="jivochat">
            <div className="row collapse">
              <Helmet>
                <script src="//code.jivosite.com/script/widget/AQKTRyCHxu" async={true}/>
            </Helmet>
            </div>
          </div>
        </Container>
      </Segment>
    );
  }
}

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { siteActions = [] } = useSelector(
    (state) => ({
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      id="footer"
    >
      <Container>
        <List.Item>
          <JivoChat/>
        </List.Item>
        <Grid className="footer-grid" textAlign="left">
          <Grid.Column>
            <List inverted>
              <List.Header>
                <img id="footer-logo" src={footerImagePath} />
                <img id="footer-logo-small" src={footerSmallImagePath} />
              </List.Header>
              <List.Content>
                <List.Item>
                  <FormattedMessage
                    id="Telephone"
                    defaultMessage="Telephone: +1 416-926-8800"
                  />
                </List.Item>
              </List.Content>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List inverted>
              <List.Header>
                <h3>
                  Intranet Insight
                </h3>
              </List.Header>
              <List.Content>
                <List.Item>
                  <FormattedMessage
                    id="IntranetInsightSubscribe"
                    defaultMessage="Subscribe and you will always be updated with the latest news from us."
                  />
                </List.Item>
                <List.Item>
                  <ConstantContactsFooter/>
                </List.Item>
              </List.Content>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List inverted>
              <List.Header>
                <h3>
                  Follow Us
                </h3>
              </List.Header>
              <List.Content className="footer-social">
                <UniversalLink href="https://twitter.com/intranet2" >
                  <img src={flattenToAppURL(twitterImagePath)}/>
                </UniversalLink>
                <UniversalLink href="https://www.linkedin.com/company/prescient-digital-media">
                  <img src={flattenToAppURL(linkedInImagePath)}/>
                </UniversalLink>
                <UniversalLink href="https://plus.google.com/108971704460817939200/videos">
                  <img src={flattenToAppURL(googleplusImagePath)}/>
                </UniversalLink>
                <UniversalLink href="https://www.youtube.com/user/prescient9">
                  <img src={flattenToAppURL(youtubeImagePath)}/>
                </UniversalLink>
                <UniversalLink href="https://www.slideshare.net/prescient">
                  <img src={flattenToAppURL(slideshareImagePath)}/>
                </UniversalLink>
              </List.Content>
            </List>
          </Grid.Column>
        </Grid>

      </Container>
      <Container textAlign="left">
        <br />
        <FormattedMessage
          id="prescient-copyright"
          defaultMessage="{copyright} Copyright {current_year} by the Prescient Digital Media. All Rights."
          values={{
            copyright: (
              <abbr>©</abbr>
            ),
            current_year: new Date().getFullYear(),
          }}
        />{' '}
        <UniversalLink class="privacy-link" href="/about-us/privacy-policy">
          Privacy Policy
        </UniversalLink>
      </Container>

    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
