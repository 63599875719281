import { useState, useEffect, useCallback, useRef } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { Form, Input } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import qs from 'query-string';

import zoomSVG from '@plone/volto/icons/zoom.svg';
import closeSVG from '@plone/volto/icons/clear.svg';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
  closeSearch: {
    id: 'Close search',
    defaultMessage: 'Close search',
  },
});

const SearchWidget = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const { pathname, history } = props;
  const searchableText = qs.parse(history.location.search).SearchableText;
  const [text, setText] = useState(searchableText);
  const [visible, setSetIsVisible] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (visible) {
      inputRef.current && inputRef.current.focus();
    }
  }, [visible]);

  const onSubmit = useCallback(
    (event) => {
      const path =
        pathname?.length > 0 ? `&path=${encodeURIComponent(pathname)}` : '';
      const encodedText = encodeURIComponent(text);
      history.push(`/search?SearchableText=${encodedText}${path}`);
      setText('');
      event.preventDefault();
    },
    [pathname, text, history],
  );

  useEffect(() => {
    setSetIsVisible(false);
  }, [location]);

  const onChangeText = (event, { value }) => {
    setText(value);
  };

  return (
    <>
      <button
        className="header-icon"
        aria-label={intl.formatMessage(messages.search)}
        onClick={() => setSetIsVisible(true)}
      >
        <Icon name={zoomSVG} size="22px" />
      </button>
      {visible && (
        <div className="header-searchfield-wrapper ui container">
          <div className="header-searchfield">
            <Form action="/search" onSubmit={onSubmit}>
              <Form.Field className="searchbox">
                <Input
                  ref={inputRef}
                  aria-label={intl.formatMessage(messages.search)}
                  onChange={onChangeText}
                  name="SearchableText"
                  value={text}
                  transparent
                  autoComplete="off"
                  placeholder={intl.formatMessage(messages.searchSite)}
                  title={intl.formatMessage(messages.search)}
                />
              </Form.Field>
            </Form>
            <button
              className="header-icon"
              aria-label={intl.formatMessage(messages.closeSearch)}
              onClick={() => setSetIsVisible(false)}
            >
              <Icon name={closeSVG} size="22px" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(SearchWidget);
