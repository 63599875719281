import { defineMessages } from 'react-intl';
import config from '@plone/volto/registry';
import { mergeSchemas } from '@kitconcept/volto-slider-block/helpers';
import { v4 as uuid } from 'uuid';

const BG_COLORS = [
  { name: 'transparent', label: 'Transparent' },
  { name: 'grey', label: 'Grey' },
  { name: 'blue', label: 'Blue' },
];

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  Slider: {
    id: 'Slider',
    defaultMessage: 'Slider',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  imageOverride: {
    id: 'Image override',
    defaultMessage: 'Image override',
  },
  slideClass: {
    id: 'Slide Class',
    defaultMessage: 'Slide Class',
  },
  sliderClass: {
    id: 'Slider Class',
    defaultMessage: 'Slider Class',
  },
  stretchImage: {
    id: 'Stretch Image',
    defaultMessage: 'Stretch Image',
  },
  disableLink: {
    id: 'Disable Link',
    defaultMessage: 'Disable Link',
  },
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  items: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  head_title: {
    id: 'Headtitle',
    defaultMessage: 'Headtitle',
  },
});

export const itemSchema = (props) =>
  mergeSchemas(
    {
      title: props.intl.formatMessage(messages.item),
      addMessage: props.intl.formatMessage(messages.addItem),
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: [
            'href',
            'head_title',
            'title',
            'description',
            'preview_image',
            'slide_class',
          ],
        },
      ],

      properties: {
        href: {
          title: props.intl.formatMessage(messages.Source),
          widget: 'object_browser',
          mode: 'link',
          selectedItemAttrs: [
            'Title',
            'Description',
            'head_title',
            'hasPreviewImage',
            'image_field',
            'image_scales',
            '@type',
          ],
          allowExternals: true,
        },
        head_title: {
          title: props.intl.formatMessage(messages.head_title),
        },
        title: {
          title: props.intl.formatMessage(messages.title),
        },
        description: {
          title: props.intl.formatMessage(messages.description),
        },
        preview_image: {
          title: props.intl.formatMessage(messages.imageOverride),
          widget: 'object_browser',
          mode: 'image',
          allowExternals: true,
        },
        slide_class: {
          title: props.intl.formatMessage(messages.slideClass),
          default: 'transparent-background',
        },
      },
      required: [],
    },
    config.blocks.blocksConfig.slider.extensions?.slideSchema || {},
  );

export const SliderSchema = (props) =>
  mergeSchemas(
    {
      title: props.intl.formatMessage(messages.Slider),
      block: 'slider',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['slider_class', 'stretch_image', 'disable_link', 'slides'],
        },
      ],
      properties: {
        slides: {
          widget: 'object_list',
          title: props.intl.formatMessage(messages.items),
          schema: itemSchema(props),
          activeObject: props.activeObject,
          setActiveObject: props.setActiveObject,
          default: [{ '@id': uuid() }],
        },
        stretch_image: {
          type: 'boolean',
          title: props.intl.formatMessage(messages.stretchImage),
          default: false,
        },
        disable_link: {
          type: 'boolean',
          title: props.intl.formatMessage(messages.disableLink),
          default: false,
        },
        slider_class: {
          title: props.intl.formatMessage(messages.sliderClass),
        },
      },
      required: [],
    },
    config.blocks.blocksConfig.slider.extensions?.blockSchema || {},
  );
